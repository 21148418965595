<template>
    <div class="view">
        <v-row id="filter-components" class="ml-5 mr-4 d-flex justify-start" no-gutters>
            <v-col class="mr-2" v-for="(filter, index) in filterComponents" :key="filter.column">
                <component
                    data-testid="znap-filter"
                    :filter="filter"
                    :column="filter.column"
                    :is="filter.is"
                    :items="setFiltersItems(filter) || []"
                    :multiple="filter.multiple || false"
                    :label="filter.label"
                    @setSelection="setSelection({ column: filter.column, selected: $event, required: filter.required })"
                    @removeSelection="removeSelection(filter.column, filter.required)"
                    @setDatepickerValue="setDatepickerValue($event)"
                    @filtersCounter="filtersCounter"
                    :clearAllFilters="clearAllFilters"
                    @changeClearAllFiltersBackToFalse="changeClearAllFiltersBackToFalse(index)"
                    :required="filter.required"
                    :disabled="disabled(filter.column)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ComboBox from '@/components/znap/ComboBox.vue'
import Datepicker from '@/components/znap/Datepicker.vue'
// import Checkbox from '@/components/znap/Checkbox.vue'

export default {
    components: {
        ComboBox, Datepicker
        // CheckBox
    },

    props: {
        filterConditions: { type: Array },
        filterComponents: { type: Array, required: true },
        noData: { type: Boolean, required: true },
        clearFilters: { type: Boolean },
    },

    data () {
        return {
            // Combobox Data
            activeFilters: null,
            toggleFilter: false,
            clearAllFilters: false,
            selectionArray: []
        }
    },

    watch: {
        clearFilters(val) {
            if (val) {
                this.activeFilters = 0
                this.clearAllFilters = true
                this.selectionArray = []

                // emit event that clear a all filters that are filteredBy
                this.$emit('clearFilteredBy')

                this.$emit('setClearFilters', false)
            }
        }
    },

    methods: {
        setFiltersItems(filter) {
            let items = filter.items

            if (filter.filteredBy) {
                this.selectionArray.forEach(s => {
                    if (s.column.includes(filter.filteredBy)) {
                        items = filter.filteredItems
                    }
                })
            }
            
            return items
        },

        disabled(filterColumn) {
            let disabled = false
            let filter = this.filterComponents.find(filter => filter.column === filterColumn)

            if (filter.is === "ComboBox") {
                if (filter.items.length === 0) disabled = true
            }

            return disabled
        },

        filtersCounter(filters) {
            this.activeFilters += filters
        },

        changeClearAllFiltersBackToFalse(index) {
            // teste para atribuir o changeClearAllFiltersBackToFalse somente para o último componente de filtros
            if (index === this.filterComponents.length - 1) {
                this.clearAllFilters = false
            }
        },

        setSelection(selection) {
            let values = []

            // emit event that filters another filter
            let found = this.filterComponents.find(f => f.column === selection.column)
            if (found.filters) {
                this.$emit('setFilterValue', { filters: found.filters, value: selection })
            }

            let verify = this.selectionArray.map( function(obj) { return obj.column }).indexOf( selection.column )

            if (verify === -1) {
                if (selection.selected.length > 0) {
                    selection.selected.forEach(selected => {
                        return values.push(selected.id)
                    })

                    this.selectionArray.push( { column: selection.column, id: values } )
                } else {
                    this.selectionArray.push( { column: selection.column, id: selection.selected.id } )
                }
            } else {
                if (selection.selected.length > 0) {
                    selection.selected.forEach(selected => {
                        return values.push(selected.id)
                    })

                    this.selectionArray.splice(verify, 1, { column: selection.column, id: values })
                } else {
                    this.selectionArray.splice(verify, 1, { column: selection.column, id: selection.selected.id })
                }
            }

            this.$emit('setSelection', this.selectionArray, selection.required)
        },

        removeSelection(filterColumn, filterRequired) {
            // emit event that clear a filter that is filteredBy
            let found = this.filterComponents.find(f => f.column === filterColumn)
            if (found.filters) {
                this.$emit('setFilterValue', { filters: found.filters, value: { column: filterColumn, selected: null } })
            }

            this.selectionArray.forEach((selection, i) => {
                if (selection.column === filterColumn) {
                    this.selectionArray.splice(i, 1)
                }
            })

            this.$emit('setSelection', this.selectionArray, filterRequired)
        },

        // setCheckboxValue(e) {
        //     this.$emit('setCheckboxValue', e)
        // },

        setDatepickerValue(e) {
            this.$emit('setDatepickerValue', e)
        }
    }
}
</script>

<style scoped>
#filter-components {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

#checkbox-components {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#chips-components {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.fade-enter, .fade-leave-to {
	opacity: 0;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity 2s;
}
</style>
